import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getBaseUrl } from '../environment-url/environment-url';
import store from '../../store/store';
import { RootState } from '../../models/RootState';
import { logout } from '../../store';

const REPORTING_API = axios.create({
  baseURL: getBaseUrl(process.env.REACT_APP_NODE_ENV, 'REACT_APP_REST_REPORTING_API_URL'),
  responseType: 'json',
  withCredentials: false,
  timeout: 10000, // 10 seconds
});

//Add a response interceptor
REPORTING_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('***********Unauthorized Error**********');
      const token = sessionStorage.getItem('jwt_token');
      if (token) {
        (store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default REPORTING_API;