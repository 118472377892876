import CricketLiveImg from '../assets/images/sportsbook/icons/cricket-live-red.png';
import TennisLiveImg from '../assets/images/sportsbook/icons/tennis-live-red.png';
import FootballLiveImg from '../assets/images/sportsbook/icons/soccer-live-red.png';
import LiveImg from '../assets/images/sportsbook/icons/live-icon.svg';
import { format } from 'date-fns';

export function formatTime(date: Date) {
  try {
    return format(date, 'HH:mm');
  } catch (err) {
    console.log(date, err);
    return date;
  }
}

export function similarity(s1: string, s2: string) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength;
}
export const MarketLangKeyByName: { [key: string]: string } = {
  matchodds: 'match_odds',
  bookmaker: 'bookmaker',
  minibookmaker: 'mini_bookmaker',
  completedmatch: 'completed_match',
  toss: 'toss',
  overbookmaker: 'over_bookmaker',
  fancy: 'fancy',
  tiedmatch: 'tied_match',
  premium: 'premium',
  whowillwinthematch: 'match_winner_txt',
  overunder: 'over_under',
  overmarket: 'over_market',
  completematch: 'complete_match'
  
};
function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs: number[] = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function formatDate(date: Date) {
  try {
    return format(date, 'dd-MM-yy hh:mm a');
  } catch (err) {
    console.log(date, err);
    return date;
  }
}

export const liveImagesMap = {
  '1': LiveImg,
  '2': LiveImg,
  '4': LiveImg,
};

export const SportIdByNameMap: { [key: string]: string } = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
};

export const OutcomeDescMap = {
  '0': 'Lost',
  '1': 'Win',
  '2': 'Unsettled',
  '11': 'Unsettled',
  '3': 'Rolled Back',
  '4': 'Void',
};

export function getSportNameByIdMap(eventType: string) {
  return SportNameByIdMap[eventType];
}
export function getMarketLangKeyByName(type: string) {
  let formattedType = type.replace(/[\s/?\d]/g, '').toLowerCase();
  return MarketLangKeyByName[formattedType];
}
export const SportNameByIdMap: { [key: string]: string } = {
  '1': 'soccer',
  '2': 'tennis',
  '4': 'cricket',
  '7522': 'basketball',
  '7511': 'baseball',
  '4339': 'greyhoundracing',
  '77': 'horseracing',
};

export const MarketTypeByEnumMap: { [key: string]: string } = {
  'MATCH_ODDS': 'Match odds',
  'BOOKMAKER': 'Bookmaker',
  'FANCY': 'Fancy',
  'PREMIUM': 'Premium',
  'CASINO': 'Casino',
  'BINARY': 'Binary',
  'INDIAN_CASINO': 'Indian Casino',
};

export const IndianCasinoMarketNameMap: { [key: string]: string } = {
  '0': 'Lucky 7B',
  '1': 'Lucky 7A',
  '2': 'Teenpatti',
  '3': 'Dragon Tiger 2020',
  '4': 'Dragon Tiger Lion',
  '5': 'Bollywood Casino AAA',
  '6': 'Race 2020',
  '7': '32 Card',
  '8': '3Card Judgement',
  '9': 'Andar Bahar',
};

export const TransactionTypeMap = {
  '0': 'Deposit',
  '1': 'Withdraw',
  '2': 'Settlement Deposit',
  '3': 'Settlement Withdraw',
  '4': 'Bet Placement',
  '5': 'Bet Settlement',
  '6': 'Rollback Bet Settlement',
  '7': 'Void Bet Settlement',
  '8': 'Casino Bet Placement',
  '9': 'Casino Bet Settlement',
  '10': 'Casino Bet Rollback',
  '11': 'Win Commission',
  '12': 'Win Commission Reversal',
  '13': 'Indian Casino Bet Placement',
  '14': 'Indian Casino Bet Settlement',
  '15': 'Commission Claim',
  '16': 'Commission Deposit',
  '17': 'Commission Rollback',
  '18': 'Commission Void',
  '19': 'Casino Bet Placement',
  '20': 'Casino Bet Settlement',
  '21': 'Casino Rollback',
  '22': '-',
  '23': 'Binary Bet Placement',
  '24': 'Binary Bet Settlement',
  '25': 'Binary Bet Rollback',
  '26': 'Bet Placement',
  '27': 'Bet Settlement',
  '28': 'Bet Rollback',
  '29': 'Bet Void',
  '30': 'Bet Cancelled',
  '31': 'Bet Expired',
  '32': 'Bet Lapsed',
  '33': 'Bet Deleted',
  '34': 'Risk Update',
  '35': 'Binary Bet Void',
  '36': 'Sport Book Bet Placement',
  '37': 'Sport Book Bet Settlement',
  '38': 'sport Book Bet Rollback',
  '39': 'Rolling Commission',
  '40': 'Win Commission Update',
  '41': 'Win Commission Update',
  '42': 'Rollback Void Bet Settlement',
  '44': 'Commission Claim',
  '45': 'Bonus Redeemed',
  '46': 'Bonus Rollback',
  '47': 'Refunds'
};

export const getBetTimeoutByMarket: { [key: string]: number } = {
  FANCY: 1,
  MO: 1,
  BM: 1,
  BINARY: 1,
  CASINO: 1,
  OT: 1,
};

export function getTransactionNameByID(trans: string) {
  return TransactionTypeMap[trans];
}

export function getOutcomeDescName(eventType: string) {
  return OutcomeDescMap[eventType];
}

export function getSportIdByName(eventType: string) {
  return SportIdByNameMap[eventType];
}

export function MarketTypeByID(id: string) {
  return MarketTypeMap[id] ? MarketTypeMap[id] : 'Casino';
}

export function DisabledBinaryEvents() {
  return ['NIFTY BANK', 'NIFTY 50'];
}

export const SportMarketMap: { [key: string]: string } = {
  'MATCH ODDS' : 'MT0.0',
  'OVER/UNDER 0.5 GOALS' : 'MT0.5',
  'OVER/UNDER 1.5 GOALS' : 'MT1.5',
  'OVER/UNDER 2.5 GOALS' : 'MT2.5',
};

export const OfflineTransactionTypeMap: { [key: string]: number } = {
  'BANK_TRANSACTION': 0,
  'UPI_PHONE_PE' : 1,
  'UPI_GPAY': 2,
  'PAYTM': 3,
}

export const OfflineTransactionStatusTypeMap: { [key: string]: number } = {
  'IN_PROGRESS': 0,
  'ACCEPTED': 1,
  'DECLINED': 2,
}

export const ThousandFormatter = (num: any, type: string) => {
  if (num > 999) {
    if (type === "min") {
    return Math.ceil(Number(num / 1000)) + 'K';
  }
    return Math.round(Number(num / 1000)) + 'K';
  } else {
    if (type === "min") {
      return Math.ceil(num);
    }
    return Math.round(num);
  }
};

export const MarketTypeMap: { [key: string]: string } = {
  '0': 'Match odds',
  '1': 'Book maker',
  '2': 'Fancy',
  '3': 'Premium Market',
  '4': 'Casino',
  '5': 'Binary',
  '6': 'Indian Casino',
};

export const capitalize = (str: string) => {
  const words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]?.toUpperCase()) {
      words[i] = words[i][0]?.toUpperCase() + words[i].substring(1);
    }
  }
  return words.join(' ');
};

export const SPToBFIdMap = {
  'sr:sport:1': '1',
  'sr:sport:5': '2',
  'sr:sport:21': '4',
  "sr:sport:2": "7522",
  "sr:sport:3": "7511",
};

export const BFToSRIdMap = {
  '1': 'sr:sport:1',
  '2': 'sr:sport:5',
  '4': 'sr:sport:21',
  "7522": "sr:sport:2",
  "7511": "sr:sport:3",
};

export const demoUserPrefix = "zzzzsysdemo";