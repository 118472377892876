import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { RootState } from './models/RootState';

import AcceptTerms from './pages/AcceptTerms/AcceptTerms';
import { fetchMailVerifiedSuccess, logout } from './store';
import ResetPassword from './pages/ResetPasswordPage/ResetPasswordPage';
import AccessRedirect from './pages/AccessRedirect/Accessredirect';
import { setTimeout } from 'timers';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import LoginPage from './pages/Login/LoginPage';
import SignUp from './pages/SignUp/SignUp';
import defaultLangData from '../src/locales/default-lang.json'
import { fetchContentConfig, setAlertMsg, setLangData, setLangSelected, setLanguages } from './store/common/commonActions';
import { defaultLang, getLang, getLangCode, getSelectedLang, getUpdatedSelectedLang } from './util/localizationUtil';
import LANG_API from './models/api/language-api';
import { AlertDTO } from './models/Alert';
import SVLS_API from './api-services/svls-api';
import { BRAND_DOMAIN } from './constants/Branding';
const MainPage = lazy(() => import('./router/UserRouter'));

type StateProps = {
  fetchMailVerifiedSuccess: (mailVerified: boolean) => void;
  logout: () => void;
  mailVerified: boolean;
  prefersDark: boolean;
  loggedIn: boolean;
  fetchContentConfig:any;
  setLanguages: (languages: string[]) => void;
  setLangSelected: (lang: string) => void;
  setLangData: (langData: any) => void;
  langData: string;
  setAlertMsg: Function;
};

const App: React.FC<StateProps> = (props) => {
  const consoleOpen = useConsoleOpen();
  // const consoleOpen = false;

  const { logout, prefersDark, loggedIn, fetchContentConfig, setLanguages, setLangSelected, setLangData, langData,
    setAlertMsg, } = props;
  const lang = getLang(sessionStorage?.getItem('lang'));

  useEffect(() => {
    const toggleTheme = (systemInitiatedDark) => {
      if (systemInitiatedDark.matches) {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        document.body.setAttribute('data-theme', 'light');
      }
    };

    let systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    systemDark.addListener((e) => toggleTheme(prefersDark));
    document.body.classList.toggle('dark', prefersDark);
  }, [prefersDark]);

  const handleOnIdle = () => {
    console.log('user is idle');
    if (loggedIn) {
      logout();
    }
  };

  const handleClose = () => {
    console.log('user open dev tools');
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2, // 2 hours
    onIdle: handleOnIdle,
    debounce: 500,
  });
  useEffect(() => {
    fetchContentConfig();
  }, []);

  const afterProcess = (languages: string[]) => {
    var sessionLang = sessionStorage.getItem('lang');
    var lang = sessionLang
      ? getUpdatedSelectedLang(languages, sessionLang)
      : getSelectedLang(languages);

    sessionStorage.setItem('lang', lang);
    setLangSelected(sessionStorage.getItem('lang'));
  };
  useEffect(() => {
    if (lang) {
      getLangData();
    }
  }, [lang]);
  useEffect(() => {
      getDomainConfig();
    }, []);
  const getDomainConfig = async () => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        var languages = response?.data?.languages;
        setLanguages(languages);

      }
    } catch (err) {
      console.log(err);
    }
    finally {
      afterProcess(languages);
    }
  };
  const getLangData = async () => {
    try {

      var langCode = getLangCode(lang)?.toLowerCase();
      const response = await LANG_API.get(`/languages/${langCode}/lang.json`);
      const data = await response.data;
      if (data) {
        setLangData(data);
      } else {
        setDefaultLangData();
      }
    } catch (error) {
      console.error('Error getting language data:', error);
      setDefaultLangData();
    }
  };

  const setDefaultLangData = () => {
    setAlertMsg({
      type: 'error',
      message: 'Language not supported.',
    });
    sessionStorage.setItem('lang', defaultLang);
    setLangSelected(defaultLang);
    setLangData(defaultLangData);
  };
  return (
    <>
      {!consoleOpen ? (
        <Suspense fallback={<LoadingPage />}>
          <BrowserRouter>
            <Switch>
              <Route path="/access-redirect" component={AccessRedirect} />
              <Route path="/terms-and-conditions" component={AcceptTerms} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/register" component={SignUp} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={SignUp} />
              <Route path="/" component={MainPage} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const useConsoleOpen = () => {
  const [consoleOpen, setConsoleOpen] = useState(false);

  useEffect(() => {
    let checkStatus;

    var element = new Image();
    Object.defineProperty(element, 'id', {
      get: function () {
        checkStatus = true;
        throw new Error('Dev tools checker');
      },
    });
    requestAnimationFrame(function check() {
      setTimeout(() => {
        checkStatus = false;
        // Don't delete this console statements
        // Uncomment fater fixing ios issues
        // if (process.env.REACT_APP_NODE_ENV !== 'development') {
        //   console.dir(element);
        //   console.clear();
        // }
        setConsoleOpen(checkStatus);
        requestAnimationFrame(check);
      }, 1000);
    });
  });

  return consoleOpen;
};

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
    langData: state.common.langData,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMailVerifiedSuccess: (mailVerified: boolean) =>
      dispatch(fetchMailVerifiedSuccess(mailVerified)),
    fetchContentConfig: () => dispatch(fetchContentConfig()),
    setLanguages: (languages: string[]) => dispatch(setLanguages(languages)),
    setLangSelected: (lang: string) => dispatch(setLangSelected(lang)),
    setLangData: (langData: any) => dispatch(setLangData(langData)),
    setAlertMsg: (alert: AlertDTO) => dispatch(setAlertMsg(alert)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
