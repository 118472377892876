import rugbyIcon from '../assets/images/sportsbook/icons/rugby.png';
import iceHockeyIcon from '../assets/images/sportsbook/icons/ice-hockey.png';
import badmintonIcon from '../assets/images/sportsbook/icons/badminton.png';
import tableTennisIcon from '../assets/images/sportsbook/icons/table-tennis.png';

export const EXCHANGE_EVENT_TYPES = [
   { id: '4', name: 'Cricket', slug: 'cricket', langKey:'cricket' },
  { id: '1', name: 'Football', slug: 'football' ,langKey:'football'},
  { id: '2', name: 'Tennis', slug: 'tennis',langKey:'tennis' },
  { id: '77', name: 'Horse Racing', slug: 'horseracing',langKey:'horseracing' },
];

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  horseracing: '77',
};

type oEvent = {
  label: string;
  img: string;
}
export const OTHER_EVENTS:oEvent[] = [
  {label: 'Rugby', img: rugbyIcon},
  {label: 'Table Tennis', img: tableTennisIcon},
  {label: 'Ice Hockey', img: iceHockeyIcon},
  {label: 'Badminton', img: badmintonIcon},
];

export const SPORTS_MAP = new Map([
  [
    'Cricket',
    { id: '4', name: 'Cricket', slug: 'cricket', priority: 0, disable: false },
  ],
  [
    'Football',
    {
      id: '1',
      name: 'Football',
      slug: 'football',
      priority: 1,
      disable: false,
    },
  ],
  [
    'Tennis',
    { id: '2', name: 'Tennis', slug: 'tennis', priority: 2, disable: false },
  ],
  [
    'Horse Racing',
    {
      id: '77',
      name: 'Horse Racing',
      slug: 'horseracing',
      priority: 3,
      disable: false,
    },
  ],
  [
    'GreyHound',
    {
      id: '4339',
      name: 'GreyHound',
      slug: 'greyhound',
      priority: 4,
      disable: false,
    },
  ],
  [
    'Basketball',
    {
      id: '7522',
      name: 'basketball',
      slug: 'basketball',
      enabled: true,
      priority: 5,
      disable: false,
    },
  ],
  [
    'Baseball',
    {
      id: '7511',
      name: 'baseball',
      slug: 'baseball',
      enabled: true,
      priority: 6,
      disable: false,
    },
  ],
]);